/* eslint-disable */
import axios from '@/plugins/axios'

export default {
  workshops() {
    return axios.get('/v1/workshops');
  },
  warehousesByWorkshop(workshopId) {
    return axios.get(`/v1/warehouses-by-workshop/${workshopId}`);
  },
  expencesProvidersByTruck(params) {

    let targetParams = {
      ...params
    }

    let targetFilter = {}


    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'date') {
          targetFilter['filter[BetweenDate]'] = targetParams.date[0] + ' 00:00:00' + ',' +
            targetParams.date[1] + ' 23:59:59'
        }

        if (key == 'location') {
          targetFilter['workshop_id'] = targetParams.location.id
        }
        if (key == 'warehouse') {
          targetFilter['warehouse'] = targetParams.warehouse.id
        }
      }
    }
    console.log(targetFilter);
    return axios.get(`/v1/expenses-by-provider`, {
      params: targetFilter
    });
  },
  chartCostSuppliesByTruck(params) {

    let targetParams = {
      ...params
    }

    let targetFilter = {}


    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'date') {
          targetFilter['filter[BetweenDate]'] = targetParams.date[0] + ' 00:00:00' + ',' +
            targetParams.date[1] + ' 23:59:59'
        }

        if (key == 'location') {
          targetFilter['filter[WorkshopId]'] = targetParams.location.id
        }
      }
    }

    return axios.get('/v1/chart_cost_supplies_by_truck', {
      params: targetFilter
    });
  },
  listCostSuppliesByTruck(params) {

    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'date') {
          targetFilter['filter[BetweenDate]'] = targetParams.date[0] + ' 00:00:00' + ',' +
            targetParams.date[1] + ' 23:59:59'
        }

        if (key == 'location') {
          targetFilter['filter[WorkshopId]'] = targetParams.location.id
        }
      }
    }
    return axios.get('/v1/list_cost_supplies_by_truck', {
      params: targetFilter
    });
  },
  downloadSummaryMaintenance(params) {

    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'from' || key == 'to') {
          targetFilter['filter[BetweenDate]'] = targetParams.from + ',' + targetParams.to
        }

        if (key == 'truck') {
          targetFilter['filter[Truck]'] = targetParams.truck
        }
        if (key == 'location' || key == null) {
          targetFilter['filter[WorkshopId]'] = targetParams.location.id
        }
        if (key == 'search') {
          targetFilter['filter[CodeOts]'] = targetParams.search
        }
        if (key == 'responsable') {
          targetFilter['filter[Specialist]'] = targetParams.responsable
        }
        if (key == 'applicant') {
          targetFilter['filter[Applicant]'] = targetParams.applicant
        }
        if (key == 'review_by') {
          targetFilter['filter[Reviewed]'] = targetParams.review_by
        }
        if (key == 'delivered_to') {
          targetFilter['filter[Delivered]'] = targetParams.delivered_to
        }
      }
    }

    return axios.get('v1/download_summary_maintenance', {
      responseType: 'blob',
      params: targetFilter
    });
  },
  downloadHistoryOrderWork(params) {
    console.log('====================================');
    console.log('downloadHistoryOrderWork');
    console.log(params);
    console.log('====================================');
    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'from' || key == 'to') {
          targetFilter['filter[BetweenDate]'] = targetParams.from + ',' + targetParams.to
        }
        if (key == 'truck') {
          targetFilter['filter[Truck]'] = targetParams.truck
        }
        if (key == 'location' || key == null) {
          targetFilter['filter[WorkshopId]'] = targetParams.location.id
        }
        if (key == 'search') {
          targetFilter['filter[CodeOts]'] = targetParams.search
        }
        if (key == 'responsable') {
          targetFilter['filter[Specialist]'] = targetParams.responsable
        }
        if (key == 'applicant') {
          targetFilter['filter[Applicant]'] = targetParams.applicant
        }
        if (key == 'review_by') {
          targetFilter['filter[Reviewed]'] = targetParams.review_by
        }
        if (key == 'delivered_to') {
          targetFilter['filter[Delivered]'] = targetParams.delivered_to
        }
      }
    }

    return axios.get('v1/history_order_workers_download', {
      responseType: 'blob',
      params: targetFilter
    });
  },
  downloadSummaryTruckWithSupplies(params) {

    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'date') {
          targetFilter['filter[BetweenDate]'] = targetParams.date[0] + ' 00:00:00' + ',' +
            targetParams.date[1] + ' 23:59:59'
        }

        if (key == 'location') {
          targetFilter['filter[WorkshopId]'] = targetParams.location.id
        }
      }
    }

    return axios.get('v1/download_summary_truck_with_supplies_maintenance', {
      responseType: 'blob',
      params: targetFilter
    });
  },

  downloadHistoricalTruck(params) {

    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'date') {
          targetFilter['filter[BetweenDate]'] = targetParams.date[0] + ' 00:00:00' + ',' +
            targetParams.date[1] + ' 23:59:59'
        }

        if (key == 'location') {
          targetFilter['filter[WorkshopId]'] = targetParams.location.id
        }

        if (key == 'truck') {
          targetFilter['filter[Truck]'] = targetParams.truck && targetParams.truck.id
        }
      }
    }

    return axios.get('v1/download_expenses_by_truck', {
      responseType: 'blob',
      params: targetFilter
    });
  },
  downloadExpencesBySupply(params) {

    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'date') {
          targetFilter['filter[BetweenDate]'] = targetParams.date[0] + ' 00:00:00' + ',' +
            targetParams.date[1] + ' 23:59:59'
        }

        if (key == 'location') {
          targetFilter['filter[WorkshopId]'] = targetParams.location.id
        }

        if (key == 'truck') {
          targetFilter['filter[Truck]'] = targetParams.truck && targetParams.truck.id
        }
      }
    }

    return axios.get('v1/download_expences_by_supply_category', {
      responseType: 'blob',
      params: targetFilter
    });
  },
  downloadEquipmentAvailability(params) {

    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'date') {
          targetFilter['filter[BetweenDate]'] = targetParams.date[0] + ' 00:00:00' + ',' +
            targetParams.date[1] + ' 23:59:59'
        }

        if (key == 'location') {
          targetFilter['filter[WorkshopId]'] = targetParams.location.id
        }

        if (key == 'truck') {
          targetFilter['filter[Truck]'] = targetParams.truck && targetParams.truck.id
        }
      }
    }

    return axios.get('v1/download_equipment_availability', {
      responseType: 'blob',
      params: targetFilter
    });
  },
  downloadExpencesByMonth(params) {

    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {

        if (key == 'location') {
          targetFilter['filter[WorkshopId]'] = targetParams.location.id
        }

        if (key == 'truck') {
          targetFilter['filter[Truck]'] = targetParams.truck && targetParams.truck.id
        }
      }
    }

    return axios.get('v1/download_expences_by_month', {
      responseType: 'blob',
      params: targetFilter
    });
  },



  expencesByTruck(params) {
    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'date') {
          targetFilter['filter[BetweenDate]'] = targetParams.date[0] + ' 00:00:00' + ',' +
            targetParams.date[1] + ' 23:59:59'
        }

        if (key == 'truck') {
          targetFilter['filter[Truck]'] = targetParams.truck && targetParams.truck.id
        }
      }
    }


    return axios.get('/v1/expenses_by_truck', {
      params: targetFilter
    });
  },
  expencesByCategory(params) {
    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'date') {
          targetFilter['filter[BetweenDate]'] = targetParams.date[0] + ' 00:00:00' + ',' +
            targetParams.date[1] + ' 23:59:59'
        }

        if (key == 'truck') {
          targetFilter['filter[Truck]'] = targetParams.truck && targetParams.truck.id
        }
      }
    }


    return axios.get('/v1/expenses_by_category_supply', {
      params: targetFilter
    });
  },
  expencesByMonth(params) {
    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'date') {
          targetFilter['filter[BetweenYear]'] = targetParams.date[0] + ' 00:00:00' + ',' +
            targetParams.date[1] + ' 23:59:59'
        }

        if (key == 'truck') {
          targetFilter['filter[Truck]'] = targetParams.truck && targetParams.truck.id
        }
      }
    }


    return axios.get('/v1/expenses_by_category_month', {
      params: targetFilter
    });
  },
  equipmentAvailability(params) {
    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'date') {
          targetFilter['filter[BetweenDate]'] = targetParams.date[0] + ' 00:00:00' + ',' +
            targetParams.date[1] + ' 23:59:59'
        }

        if (key == 'truck') {
          targetFilter['filter[Truck]'] = targetParams.truck && targetParams.truck.id
        }
      }
    }


    return axios.get('/v1/equipment_availability', {
      params: targetFilter
    });
  }
};
