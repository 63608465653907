<template>
  <v-container fluid>
    <div>
      <v-btn icon @click="useAppStore.toogleGeneralDrawer(false, '');">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      Filtro por rango de fecha
    </div>
    <br>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-text-field type="date" v-model="filters.from" outlined label="Desde" dense hide-details>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field type="date" v-model="filters.to"
         outlined label="Hasta" dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-btn color="orange" block elevation="0" @click="applyFilterRange">Aplicar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import { useAppStore } from '@/store/app';
import { useReportsStore } from '../store/reports';
import { useReportsExpensesByCategoryStore } from '../store/reportExpensesByCategory';

export default {
  data() {
    return {
      useReportsStore: useReportsStore(),
      useReportsExpensesByCategoryStore: useReportsExpensesByCategoryStore(),
      useAppStore: useAppStore(),
      loading: false,
      filters: {
        from: '',
        to: '',
      },
      errors: {},
    };
  },
  created() {
  },
  methods: {
    applyFilterRange() {
      this.useReportsExpensesByCategoryStore.filters.date = [
        moment(this.filters.from).format('YYYY-MM-DD').toString(),
        moment(this.filters.to).format('YYYY-MM-DD').toString()];
      this.useReportsExpensesByCategoryStore.getExpencesByCategory(
        this.useReportsExpensesByCategoryStore.filters,
      );
    },
  },
};
</script>
