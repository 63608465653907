/* eslint-disable implicit-arrow-linebreak */
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: {
      name: 'Ordenes de trabajo',
    },
    component: () => import(/* webpackChunkName: "main" */ '../layouts/default/DefaultView.vue'),
    children: [
      {
        path: 'ordenes-de-trabajo',
        name: 'Ordenes de trabajo',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "ots" */ '../worker_order/views/OtsView.vue'),
      },
      {
        path: 'historial-ordenes-de-trabajo',
        name: 'Historial Ots',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "ots-history" */ '../worker_order/views/HistoryOtsView.vue'),
      },
      {
        path: 'reportes',
        name: 'Reportes',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "ots-history" */ '../reports/views/IndexReport.vue'),
        children: [
          {
            path: 'expenses-by-truck',
            name: 'Reportes',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "expenses-by-suppliers" */
                '../reports/views/ListReportsView.vue'
              ),
          },
          {
            path: 'expenses-by-suppliers',
            name: 'Reportes',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "expenses-by-suppliers" */
                '../reports/views/ExpensesBySuppliersView.vue'
              ),
          },
          {
            path: 'historical-expenses',
            name: 'Reportes',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "expenses-by-suppliers" */
                '../reports/views/HistoricalExpensesView.vue'
              ),
          },
          {
            path: 'expenses-by-category',
            name: 'Reportes',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "expenses-by-suppliers" */
                '../reports/views/ExpensesByCategoryView.vue'
              ),
          },
          {
            path: 'expenses-by-month',
            name: 'Reportes',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "expenses-by-suppliers" */
                '../reports/views/ExpensesByMonth.vue'
              ),
          },
          {
            path: 'equipment-availability',
            name: 'Reportes',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "expenses-by-suppliers" */
                '../reports/views/EquipmentAvailability.vue'
              ),
          },
          // You can add more child routes as needed
        ],
      },
      {
        path: 'inventario',
        name: 'Inventario',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "supplies" */ '../inventory/views/InventoryView.vue'),
      },
      {
        path: '/insumo/:id',
        name: 'inventario insumos',
        meta: {
          requiresAuth: true,
        },
        props: true,
        component: () =>
          import(/* webpackChunkName: "supplies" */ '../inventory/views/ShowSupplies.vue'),
      },
      {
        path: '/nuevo-insumo',
        name: 'inventario crear insumo',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "supplies" */ '../inventory/views/CreateSupplies.vue'),
      },
      {
        path: '/warehouse',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "supplies" */ '../warehouse/views/WarehouseView.vue'),
        children: [
          {
            path: '/',
            name: 'Listar bodega',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "warehouse" */
                '../warehouse/views/ListWarehouseView.vue'
              ),
          },
          {
            path: 'warehouse/create',
            name: 'Crear bodega',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "warehouse" */
                '../warehouse/views/CreateWarehouseView.vue'
              ),
          },
          {
            path: '/warehouse/:id',
            name: 'Ver bodega',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "warehouse" */
                '../warehouse/views/ContainerWarehouseView.vue'
              ),
            children: [
              {
                path: '',
                name: 'General',
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "warehouse" */
                    '../warehouse/components/FormWarehouse.vue'
                  ),
              },
              {
                path: 'stock',
                name: 'Inventario',
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(/* webpackChunkName: "warehouse" */ '../warehouse/views/StockView.vue'),
              },
              {
                path: 'purchases-orders',
                name: 'Ordenes de compra',
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "warehouse" */
                    '../warehouse/views/PurchaseOrdersView.vue'
                  ),
              },
              {
                path: 'purchases',
                name: 'Entradas',
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "warehouse" */
                    '../warehouse/views/PurchasesView.vue'
                  ),
              },
              {
                path: 'sales',
                name: 'Salidas',
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(/* webpackChunkName: "warehouse" */ '../warehouse/views/SalesView.vue'),
              },
              {
                path: 'movement-orders',
                name: 'Historial de movimeintos',
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "warehouse" */
                    '../warehouse/views/MovementHistory.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: '/maintenance-alerts',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "supplies" */ '../maintenance-alerts/views/AlertsView.vue'),
        children: [
          {
            path: '/',
            name: 'Listar Alertas',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "warehouse" */
                '../maintenance-alerts/views/ListAlertsView.vue'
              ),
          },
        ],
      },
      {
        path: '/provider',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "supplies" */ '../provider/views/ProviderView.vue'),
        children: [
          {
            path: '/',
            name: 'Proveedores',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(/* webpackChunkName: "warehouse" */ '../provider/views/ListProviderView.vue'),
          },
          {
            path: 'provider/create',
            name: 'Crear proveedor',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "warehouse" */
                '../provider/views/CreateProviderView.vue'
              ),
          },
          {
            path: '/provider/:id',
            name: 'Ver proveedor',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "warehouse" */
                '../provider/views/ContainerProviderView.vue'
              ),
            children: [
              {
                path: '',
                name: 'General',
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "warehouse" */
                    '../provider/components/FormProvider.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: '/provider',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "supplies" */
          '../provider/views/ProviderView.vue'
        ),
        children: [{
          path: '/',
          name: 'Proveedores',
          meta: {
            requiresAuth: true,
          },
          component: () => import(/* webpackChunkName: "warehouse" */
            '../provider/views/ListProviderView.vue'
          ),
        },
        {
          path: 'provider/create',
          name: 'Crear proveedor',
          meta: {
            requiresAuth: true,
          },
          component: () => import(/* webpackChunkName: "warehouse" */
            '../provider/views/CreateProviderView.vue'
          ),
        },
        {
          path: '/provider/:id',
          name: 'Ver proveedor',
          meta: {
            requiresAuth: true,
          },
          component: () => import(/* webpackChunkName: "warehouse" */
            '../provider/views/ContainerProviderView.vue'
          ),
          children: [
            {
              path: '/provider/:id/services',
              name: 'Servicios',
              meta: {
                requiresAuth: true,
              },
              component: () => import(/* webpackChunkName: "warehouse" */
                '../provider/views/ServicesView.vue'
              ),
            }, {
              path: '',
              name: 'General',
              meta: {
                requiresAuth: true,
              },
              component: () => import(/* webpackChunkName: "warehouse" */
                '../provider/components/FormProvider.vue'
              ),
            },
          ],
        },
        ],
      },
      {
        path: '/truck',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "supplies" */
          '../trucks/views/TruckView.vue'
        ),
        children: [
          {
            path: '/',
            name: 'Equipos',
            meta: {
              requiresAuth: true,
            },
            component: () => import(/* webpackChunkName: "warehouse" */
              '../trucks/views/ListTruckView.vue'
            ),
          },
          {
            path: '/truck/create',
            name: 'Crear equipo',
            meta: {
              requiresAuth: true,
            },
            component: () => import(/* webpackChunkName: "warehouse" */
              '../trucks/views/CreateTruckView.vue'
            ),
            children: [
              {
                path: '',
                name: 'General',
                meta: {
                  requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "warehouse" */
                  '../trucks/components/FormTruck.vue'
                ),
              },
            ],
          },
          {
            path: '/truck/:id',
            name: 'Ver equipo',
            meta: {
              requiresAuth: true,
            },
            component: () => import(/* webpackChunkName: "warehouse" */
              '../trucks/views/ContainerTruckView.vue'
            ),
            children: [
              {
                path: '',
                name: 'General',
                meta: {
                  requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "warehouse" */
                  '../trucks/components/FormTruck.vue'
                ),
              },
            ],
          },
        ],
      },
      // {
      //   path: '/settings',
      //   meta: {
      //     requiresAuth: true,
      //   },
      //   component: () =>
      //     import(/* webpackChunkName: "supplies" */ '../settings/views/SettingView.vue'),
      //   children: [
      //     {
      //       path: 'settings/users',
      //       name: 'Crear proveedor',
      //       meta: {
      //         requiresAuth: true,
      //       },
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "warehouse" */
      //           '../users/views/ContainerUserList.vue'
      //         ),
      //     },
      //   ],
      // },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/auth/view/AuthView.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
