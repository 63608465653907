/* eslint-disable */
import {
  defineStore,
} from 'pinia';
import moment from 'moment';
import ReportService from '../services/ReportsService';

export const useReportsExpensesByCategoryStore = defineStore('useReportsExpensesByCategoryStore', {
  state: () => ({
    filters: {
      location: '',
      truck: '',
      date: [
        moment().startOf('month').format('YYYY-MM-DD').toString(),
        moment().endOf('month').format('YYYY-MM-DD').toString(),
      ],
    },
    workshops: [],
    expences: [],
    total_all: 0,
    chartData: {
        labels: [],
        datasets: [
          {
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: 'orange',
        plugins: {
          legend: {
            display: false,
          },
        },
      },
  }),
  actions: {
    async getExpencesByCategory(params) {
      this.chartData.labels = [];
      this.chartData.datasets= [{data:[]}];
      this.loading = true;
      try {
        let targetFetchReport = ReportService.expencesByCategory(params)

        const listWorkshops = await ReportService.workshops();

        const response = await targetFetchReport;
        if (listWorkshops.data.length === 1) {
          this.filters.location = listWorkshops.data[0];
        }

        this.chartData.labels = response.data.map((item) => item.category);
        this.chartData.datasets[0].data = response.data.map((item) => item.total);
        this.total_all = response.data.reduce((acc, item) => acc + item.total, 0);
        this.workshops = listWorkshops.data;
        this.expences = response.data

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
