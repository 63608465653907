/* eslint-disable */
import {
  defineStore,
} from 'pinia';
import moment from 'moment';
import ReportService from '../services/ReportsService';

export const useReportsStore = defineStore('reports', {
  state: () => ({
    filters: {
      location: '',
      truck: '',
      date: [
        moment().startOf('month').format('YYYY-MM-DD').toString(),
        moment().endOf('month').format('YYYY-MM-DD').toString(),
      ],
    },
    workshops: [],
    expences: [],
    total_all: 0,
    loading : false,
    total_expenses : 0,
  }),
  actions: {
    async getExpencesByTruck(params) {

      this.loading = true;
      try {
        let targetFetchReport = ReportService.expencesByTruck(params)

        const listWorkshops = await ReportService.workshops();

        const response = await targetFetchReport;

        if (listWorkshops.data.length === 1) {
          this.filters.location = listWorkshops.data[0];
        }
        this.workshops = listWorkshops.data;
        this.expences = response.data.data
        this.total_all = response.data.total
        this.total_expenses = response.data.total

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
